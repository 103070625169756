import React from "react";
import { Link } from "gatsby";
import { useHits } from "react-instantsearch-hooks-web";

interface Result {
  id: string;
  title: string;
  url: string;
  description: string;
}

const SearchPage: React.FC = () => {
  const data = useHits();

  return (
    <div className="max-w-screen-sm mx-auto pt-8 px-4 pb-12">
      <h1 className="text-4xl mb-1">Search Results</h1>
      <h3 className="mb-8">
        {data.results.nbHits > 20 ? 20 : data.results.nbHits} pages matching
        &quot;{data.results.query}&quot;
      </h3>
      <div>
        {data.hits.map((hit) => {
          const { url, title, description } = hit as unknown as Result;

          return (
            <div key={url}>
              <Link to={url}>
                <h2 className="text-clhbid-orange hover:underline">{title}</h2>
              </Link>
              <p dangerouslySetInnerHTML={{ __html: description }} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SearchPage;
